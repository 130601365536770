const DOMAIN_URL = {
  MLA: '.mercadopago.com.ar',
  MLB: '.mercadopago.com.br',
  MLC: '.mercadopago.cl',
  MLM: '.mercadopago.com.mx',
};

const BASE_URL = {
  MLA: 'https://www.mercadopago.com.ar',
  MLB: 'https://www.mercadopago.com.br',
  MLC: 'https://www.mercadopago.cl',
  MLM: 'https://www.mercadopago.com.mx',
};

const LANDING_PAGE_URL = {
  MLA: `${BASE_URL.MLA}/revendedores`,
  MLB: `${BASE_URL.MLB}/revendedores`,
  MLC: `${BASE_URL.MLC}/revendedores`,
  MLM: `${BASE_URL.MLM}/representantes`,
};

const MICROSITIO_URL = {
  MLA: `${BASE_URL.MLA}/resellers`,
  MLB: `${BASE_URL.MLB}/resellers`,
  MLC: `${BASE_URL.MLC}/resellers`,
  MLM: `${BASE_URL.MLM}/resellers`,
};

const MP_CREATE_ACCOUNT_URL = {
  MLA: `${BASE_URL.MLA}/hub/registration/fiscal-splitter`,
  MLB: `${BASE_URL.MLB}/hub/registration/fiscal-splitter`,
  MLC: `${BASE_URL.MLC}/hub/registration/splitter`,
  MLM: `${BASE_URL.MLM}/hub/registration/splitter`,
};

const ACCOUNT_CONFIRMATION_URL = {
  MLA: `${MICROSITIO_URL.MLA}/account-confirmation/confirm`,
  MLB: `${MICROSITIO_URL.MLB}/account-confirmation/confirm`,
  MLC: `${MICROSITIO_URL.MLC}/account-confirmation/confirm`,
  MLM: `${MICROSITIO_URL.MLM}/account-confirmation/confirm`,
};

const LOGOUT_URL = {
  MLB: 'https://www.mercadolivre.com/jms/mlb/lgz/logout',
  MLC: 'https://www.mercadolibre.com/jms/mlc/lgz/logout',
};

const TYC_BY_SITE = {
  MLB: `${BASE_URL.MLB}/ajuda/regulamento-programa-parceiros-mercado-pago_4383`,
  MLC: `${BASE_URL.MLC}/ayuda/26313`,
};

const INVITE_AND_WIN = {
  MLB: `${BASE_URL.MLB}/mp/convide-e-ganhe`,
  MLC: `${BASE_URL.MLB}/mp/invita-y-gana`,
};

const HELP_URL = {
  MLC: `${BASE_URL.MLC}/ayuda/26313`,
};

const YOUTUBE_VIDEO_URL = {
  MLC: 'https://www.youtube.com/@AcademiaresellersMpC',
};

const URL_PER_SITE = {
  MLA: {
    MICROSITIO: MICROSITIO_URL.MLA,
    ACCOUNT_CONFIRMATION: ACCOUNT_CONFIRMATION_URL.MLA,
    MP_CREATE_ACCOUNT_URL: MP_CREATE_ACCOUNT_URL.MLA,
  },
  MLB: {
    MICROSITIO: MICROSITIO_URL.MLB,
    ACCOUNT_CONFIRMATION: ACCOUNT_CONFIRMATION_URL.MLB,
    MP_CREATE_ACCOUNT_URL: MP_CREATE_ACCOUNT_URL.MLB,
  },
  MLC: {
    MICROSITIO: MICROSITIO_URL.MLC,
    ACCOUNT_CONFIRMATION: ACCOUNT_CONFIRMATION_URL.MLC,
    MP_CREATE_ACCOUNT_URL: MP_CREATE_ACCOUNT_URL.MLC,
  },
  MLM: {
    MICROSITIO: MICROSITIO_URL.MLM,
    ACCOUNT_CONFIRMATION: ACCOUNT_CONFIRMATION_URL.MLM,
    MP_CREATE_ACCOUNT_URL: MP_CREATE_ACCOUNT_URL.MLM,
  },
};

module.exports = {
  DOMAIN_URL,
  HELP_URL,
  INVITE_AND_WIN,
  LANDING_PAGE_URL,
  LOGOUT_URL,
  MICROSITIO_URL,
  MP_CREATE_ACCOUNT_URL,
  TYC_BY_SITE,
  URL_PER_SITE,
  YOUTUBE_VIDEO_URL,
};
