const TITLE = {
  MLA: 'Convertite en revendedor Point con Mercado Pago',
  MLB: 'Ganhe dinheiro extra vendendo maquininha',
  MLC: 'Gana dinero al revender máquinas de cobro Point',
  MLM: 'Conviertete en Representante Point con Mercado Pago.',
};

const DESCRIPTION = {
  MLA: 'Ganá dinero con Mercado Pago Point. Accedé al formulario para registrarte como Revendedor de lectores de tarjetas. Mi Mercado Pago, mi manera.',
  MLB: 'Lucre com a revenda de maquininhas e ganhe incentivos em dinheiro em até 5 dias úteis. Cadastre-se já no Programa Renda na Mão!',
  MLC: 'Ingresa al Programa de Revendedores y obtén premios según tu desempeño. ¡Entre más creces en el programa, más ganas!',
  MLM: 'Gana dinero con Mercado Pago Point, entra a formulario para registrarte como revendedor de lectores de tarjetas Point.',
};

const KEYWORDS = {
  MLC: 'Programa de Revendedores, Revendedores de Mercado Pago, Revendedores de máquinas Point, Reventa de máquinas de cobro, Cómo revender máquinas Point, Mercado Pago, Mercado Pago Chile, Máquina de cobro, Máquina de cobro Point, Máquina de cobro Mercado Pago, Point Smart, Point Mini',
};

module.exports = { TITLE, DESCRIPTION, KEYWORDS };
