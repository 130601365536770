const restclient = require('nordic/restclient');

module.exports = (api) => {
  const request = restclient({
    baseURL: api,
    timeout: 3_000,
  });

  return request;
};
