const React = require('react');
const PropTypes = require('prop-types');

const { Message, MessageActions } = require('@andes/message');

const Cookie = require('@utils/cookie');

const { DOMAIN_URL } = require('@domain/constants/external-urls');

const QaMessage = ({ siteId }) => {
  const meliLabCookieName = 'meliLab';
  const appVersionName = 'Atualização Landing MLC';
  const meliLabCookie = Cookie.getCookie(meliLabCookieName);

  const themeCookieName = 'app-theme';
  const themeCookie = Cookie.getCookie(themeCookieName);

  const changeTheme = () => {
    const inTenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);

    const themeColor = themeCookie === 'yellowblue-light' ? 'blue' : 'yellowblue-light';

    Cookie.removeCookie(themeCookieName, { domain: DOMAIN_URL[siteId] });

    Cookie.generateCookie(themeCookieName, themeColor, inTenMinutes);

    window.location.reload();
  };

  return (
    !!meliLabCookie && (
      <section className="p-16 relative-item z-index-2">
        <Message
          actions={
            <MessageActions primary={{ text: 'Cambiar tema', onClick: () => changeTheme() }} />
          }
          actionsPosition="bottom"
          color="accent"
        >
          Estás en ambiente: <b>{meliLabCookie}</b> - Con la version: <b>{appVersionName}</b>
        </Message>
      </section>
    )
  );
};

QaMessage.propTypes = {
  siteId: PropTypes.string.isRequired,
};

module.exports = QaMessage;
